import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import Layout from '../layouts/Layout';
import Seo from '../components/Seo/Seo';

const Imprint = () => {
  const intl = useIntl();

  const isDeLang = () => intl.locale === 'de';

  return (
    <Layout
      section={'payment'}
      bannerTitle={intl.formatMessage({
        id: 'footer_imprint',
        defaultMessage: 'Imprint',
      })}
    >
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns">
              <div className="column">
                {isDeLang() ? (
                  <div className="content px-6">
                    <h5>VET Event Tickets e.U.</h5>
                    <p>Favoritenstraße 88-90</p>
                    <p>1100 Wien</p>
                    <p>Österreich</p>
                    <p><strong>Tel:</strong><a href="tel:+43 6767984066"> +43 6767984066</a></p>
                    <p><strong>Email:</strong><a href="mailto:office@viennaeventtickets.com"> office@viennaeventtickets.com</a></p>
                    <br />
                    <p>Firmenbuchnummer: <strong>FN 600939 i</strong></p>
                    <p>Firmenbuchgericht: Handelsgericht Wien</p>
                    <p>UID Nummer:  <strong>ATU79242446</strong></p>
                    <br />
                    <p>Mitglied der Wirtschaftskammer Wien, Sparte Tourismus- und Freizeitbetriebe, Gewerbeschein für „Organisation und Vermittlung von Veranstaltungen, Kongressen“</p>
                    <br />
                    <p>(§ 6 Abs 1 Z 27 Umsatzsteuergesetz - UStG)</p>

                  </div>
                ) : (
                  <div className="content px-6">
                    <h5>VET Event Tickets e.U.</h5>
                    <p>Favoritenstraße 88-90</p>
                    <p>1100 Wien</p>
                    <p>Österreich</p>
                    <p><strong>Tel:</strong><a href="tel:+43 6767984066"> +43 6767984066</a></p>
                    <p><strong>Email:</strong><a href="mailto:office@viennaeventtickets.com"> office@viennaeventtickets.com</a></p>
                    <br />
                    <p>Firmenbuchnummer: <strong>FN 600939 i</strong></p>
                    <p>Firmenbuchgericht: Handelsgericht Wien</p>
                    <p>UID Nummer:  <strong>ATU79242446</strong></p>
                    <br />
                    <p>Mitglied der Wirtschaftskammer Wien, Sparte Tourismus- und Freizeitbetriebe, Gewerbeschein für „Organisation und Vermittlung von Veranstaltungen, Kongressen“</p>
                    <br />
                    <p>(§ 6 Abs 1 Z 27 Umsatzsteuergesetz - UStG)</p>
                  </div>
                )}
              </div>
            </div>
          </>
        </div>
      </section>
    </Layout>
  );
};

export default Imprint;
export const Head = ({ location, pageContext }) => (
  <Seo
    title={pageContext.intl.messages.footer_imprint}
    pathname={location.pathname}
  />
);